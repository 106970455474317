.nav-container {
  @include media-breakpoint-down(sm) {
    background-color: gray("100");
  }
  @include media-breakpoint-up(md) {
    border-top: 1px solid $black;
  }
}
.navbar-toggler {
  border: none;
}
.navbar-nav {
  font-size: 0.875rem;
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
  .nav-item {
    @include media-breakpoint-up(md) {
      border-left: 1px solid $black;
      // text-align: left;
    }
    &:first-child {
      @include media-breakpoint-down(sm) {
        border-top: 1px solid $black;
      }
    }
    &:last-child {
      @include media-breakpoint-up(md) {
        border-right: 1px solid $black;
      }
    }
  }

  .nav-link {
    @include media-breakpoint-down(sm) {
      border-bottom: 1px solid $black;
      padding: map-get($spacers, 3) 0;
    }
  }
}
