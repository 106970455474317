.subscripcions {
    .dropdown-toggle {
        text-overflow: ellipsis;
        overflow: hidden;
        transition: none;
        &[aria-expanded="true"] {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: 0;
            box-shadow: none !important;
            background-color: inherit;
            color: inherit;
        }
        &::after {
            display: none;
        }
        &:hover,
        &:focus {
            &[aria-expanded="false"] {
                svg {
                    filter: invert(100%);
                }
            }
        }
        svg {
            right: 1rem;
            top: 1.75rem;
        }
    }
    .dropdown-menu {
        right: 0;
        border: 2px solid #343a40;
        border-top: 0;
        margin-top: 0;
        border-radius: 0 0 2rem 2rem;
        .btn {
            &:hover,
            &:focus {
                svg {
                    filter: invert(100%);
                }
            }
        }
    }
    .dropdown-item {
        &:hover,
        &:focus {
            background-color: transparent;
        }
    }
    ul {
        font-size: 0.875rem;
        white-space: normal;
    }
}
