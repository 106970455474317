footer {
  border-top: solid map-get($spacers, 3) gray("100");
  border-bottom: solid map-get($spacers, 1) $black;
  p,
  address,
  ul {
    font-size: 0.85rem;
  }
}
.logo-bopb-peu {
  width: 111px;
  height: 46px;
  @include media-breakpoint-up(sm) {
    height: 58px;
    width: auto;
  }
  @include media-breakpoint-up(md) {
    height: 69px;
  }
}
.logo-diba-peu {
  width: 109px;
  height: 35px;
  @include media-breakpoint-up(sm) {
    height: 45px;
    width: auto;
  }
}
