.datepicker {
  border-radius: 2rem;
  border-width: 2px;
  padding: 1rem;
  &::before,
  &::after {
    display: none;
  }
  table {
    border-collapse: inherit;
    // font-weight: 500;
    tr td {
      &.active,
      &.active.highlighted {
        background-color: $primary !important;
      }
      &.disabled,
      &.disabled:hover {
        color: #767676;
        // font-weight: 300;
      }
      &.new:not(.disabled),
      &.new:not(.disabled):hover,
      &.old:not(.disabled),
      &.old:not(.disabled):hover {
        color: #000;
      }
      span {
        &.active,
        &.active:hover,
        &.active.disabled,
        &.active.disabled:hover {
          background-color: $primary !important;
        }
      }
    }
  }
  table tr td,
  table tr th {
    width: 35px;
    height: 35px;
  }
  .datepicker-switch {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.875rem;
    border: 2px solid #c6c6c6;
    border-radius: 30.5px;
  }
}
.datepicker-days thead {
  tr:last-child {
    visibility: hidden;
    th {
      padding: 0;
      height: 0.5rem;
      font-size: 0;
    }
  }
}
