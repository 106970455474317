.sr-only-focusable {
  &:active,
  &:focus {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }
}

// https://v4-alpha.getbootstrap.com/getting-started/accessibility/#skip-navigation
#content,
#nav {
  &:focus {
    outline: none;
  }
}

.text-decoration-none:hover {
  text-decoration: underline !important;
}

// .filterForm .form-row.align-items-end .btn-primary.rounded-circle.btn-search  {
//   transform: translate(0,-13px);
// }

// card verificació
.card--verifica {
  .card-footer a {
    padding-left: 2rem;
  }
  svg.position-absolute {
    left: 0;
  }
}

// ocultar quan hi ha js
.js .js-hidden {
  display: none;
}
.no-js .collapse:not(.show) {
  display: block;
}

.text-error {
  color: rgb(231, 111, 126) !important;
}

div.imatge-capcalera {
  display: flex;
  justify-content: center;
}

//eleccions
.municipis {
  position: relative;
  padding-bottom: 4em;

  .legend {

    @include media-breakpoint-down(sm) {
      float: right;
    }
    @include media-breakpoint-up(md) {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    font-size: .8em;
    list-style: none;
    margin: 0;
    padding: 1em;
    li {
      padding: .1em 0;
      a {
        text-decoration: none;
        color: #000;
        span {
          display: inline-block;
          height: 1em;
          width: 2em;
          margin-right: 1em;
        }
      }
    }
  }
}
