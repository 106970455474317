// overrides
.vex.vex-theme-plain .vex-content {
  padding: 2rem;
  font-family: inherit;
  color: #333;
  border-radius: 2em;
  text-align: center;
}
.vex.vex-theme-plain .vex-content-large {
    width: 600px;
}
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-message {
  font-size: 1rem;
  line-height: 1.4;
}
.vex.vex-theme-plain .vex-dialog-button.vex-dialog-button-primary,
.vex.vex-theme-plain .vex-dialog-button.vex-dialog-button-secondary {
/*   border-radius: 4px; */
/*   padding: 0.66rem 1rem; */
  margin-bottom: 0;
/*   padding: 0.25rem 1.25rem; */
  border: none;
  margin: 0 .5em;
  text-transform: none;
  float: none;
  letter-spacing: normal;
}
.vex.vex-theme-plain .vex-dialog-button.vex-dialog-button-primary {
  @extend .btn;
  @extend .btn-primary;
}
.vex.vex-theme-plain .vex-dialog-button.vex-dialog-button-secondary {
  @extend .btn;
  @extend .btn-secondary;
}

.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input select,
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input textarea,
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="date"],
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="datetime"],
.vex.vex-theme-plain
  .vex-dialog-form
  .vex-dialog-input
  input[type="datetime-local"],
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="email"],
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="month"],
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="number"],
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="password"],
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="search"],
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="tel"],
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="text"],
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="time"],
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="url"],
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="week"] {
  padding: 0.75rem 1rem;
  background-color: #f9f9f9;
  border: solid 1px #dfdfdf;
  box-shadow: none;
  height: auto;
  font-size: 0.875rem;
  appearance: none;
  border-radius: 4px;
}
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input select:focus,
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input textarea:focus,
.vex.vex-theme-plain
  .vex-dialog-form
  .vex-dialog-input
  input[type="date"]:focus,
.vex.vex-theme-plain
  .vex-dialog-form
  .vex-dialog-input
  input[type="datetime"]:focus,
.vex.vex-theme-plain
  .vex-dialog-form
  .vex-dialog-input
  input[type="datetime-local"]:focus,
.vex.vex-theme-plain
  .vex-dialog-form
  .vex-dialog-input
  input[type="email"]:focus,
.vex.vex-theme-plain
  .vex-dialog-form
  .vex-dialog-input
  input[type="month"]:focus,
.vex.vex-theme-plain
  .vex-dialog-form
  .vex-dialog-input
  input[type="number"]:focus,
.vex.vex-theme-plain
  .vex-dialog-form
  .vex-dialog-input
  input[type="password"]:focus,
.vex.vex-theme-plain
  .vex-dialog-form
  .vex-dialog-input
  input[type="search"]:focus,
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="tel"]:focus,
.vex.vex-theme-plain
  .vex-dialog-form
  .vex-dialog-input
  input[type="text"]:focus,
.vex.vex-theme-plain
  .vex-dialog-form
  .vex-dialog-input
  input[type="time"]:focus,
.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="url"]:focus,
.vex.vex-theme-plain
  .vex-dialog-form
  .vex-dialog-input
  input[type="week"]:focus {
  box-shadow: none;
  border-color: $primary;
}
