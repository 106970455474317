.ods-selector {
    legend img {
        vertical-align: sub;
    }
    // checkboxes
    // hide the checkbox accessibly
    input {
        z-index: -1;
        /* remove the checkbox from the page flow, positioning it on top of the SVG */
        position: absolute;
        /* set same dimensions as the SVG */
        width: 1em;
        height: 1em;
        /* hide it */
        opacity: 0.00001;
        &:checked {
            + svg {
                display: block;
            }
        }
    }
    &__collapsible {
        border: 2px solid #ced4da;
        border-radius: 2rem;
        button {
            width: 100%;
            background-color: transparent;
            border: 0;
            font-size: 1.125rem;
            padding: 0;
            &:hover {
                text-decoration: underline;
            }
            &:focus:not(:focus-visible) {
                outline: none;
            }
            &::after {
                content: '';
                width: 17px;
                height: 9px;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSIjMDAwIiBkPSJtMCA5IDgtOSA5IDl6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=");
                display: block;
                position: absolute;
                right: 1.5rem;
                top: .5em;
                transform: rotate(.5turn);
            }
            &[aria-expanded="true"]::after {
                transform: none;
            }
        }
        label {
            border: 1px solid transparent;
            border-radius: 13px;
            width: 100%;
            &:hover,
            &.focused-label {
                background-color: #f2f2f2;
            }
            &.border-dark:hover {
                background-color: transparent;
            }
            svg {
                display: none;
                right: .75rem;
                top: .75rem;
            }
        }
    }
}
