// Variables overrides
// -------------------
@import "custom/variables";

// Bootstrap
// ---------

// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Optional
// @import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
// @import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
// @import "../../node_modules/bootstrap/scss/button-group";
// @import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
// @import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
// @import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
// @import "../../node_modules/bootstrap/scss/progress";
// @import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
// @import "../../node_modules/bootstrap/scss/close";
// @import "../../node_modules/bootstrap/scss/toasts";
// @import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
// @import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/utilities";
// @import "../../node_modules/bootstrap/scss/print";

// Lib
// ---
@import "lib/bootstrap-datepicker4"; /*https://github.com/Alanaktion/bootstrap4-datepicker*/

// Custom
// ------
@import "custom/components/bootstrap-datepicker4-overwrites";
@import "custom/components/buttons";
@import "custom/components/cookies";
@import "custom/components/days-list";
@import "custom/components/calendar"; /*https://bopbweb2020.diputaciobarcelona.antaviana.net/anteriors*/
@import "custom/components/filtres";
@import "custom/components/flash";
@import "custom/components/flow";
@import "custom/components/footer";
@import "custom/components/forms";
@import "custom/components/header";
@import "custom/components/header-tools";
@import "custom/components/mssg";
@import "custom/components/navbar";
@import "custom/components/ods-list";
@import "custom/components/ods-selector";
@import "custom/components/ods-title";
@import "custom/components/subscripcions";
@import "custom/components/typography";
@import "custom/components/varis";
@import "custom/components/vex";
@import "custom/components/ihavecookies";
