// Estils agafats del CIDO
// -----------------------

// Situació
// .level-2 .situacio {
//     padding-top: 0.15em;
// }

.level-2 .situacio.scroll-to-fixed-fixed {
    z-index: 2000 !important;
}
.situacio {
    // background-color: #f4f4f4;
    // font-size: 20px;
    // height: 70px;
    // padding: 0.75em 0 0 0;
    // position: relative;
}
// .situacio a {
//     padding-right: 0;
//     padding-left: 24px;
//     color: #4a4a4a;
// }
// .situacio a:hover {
//     text-decoration: underline;
// }
// .situacio a i {
//     position: relative;
//     top: 3px;
// }
// .situacio span.filter {
//     display: block;
//     font-size: 0.8em;
//     padding-left: 2em;
// }
// .situacio .btn {
//     position: absolute;
//     right: 24px;
//     top: 16px;
// }
.situacio a.close {
	float: right;
}
@media (min-width: 992px) {
    .situacio a {
/*         display: block; */
/*         max-width: 230px; */
/*         max-height: 32px; */
/*         overflow: hidden; */
    }
}
@media (min-width: 1024px) {
    .situacio a {
/*         max-width: 280px; */
    }
}

.main-nav.overlay-menu {
    display: none;
    z-index: 1000;
}

.main-nav .navegacio-interna {
    // padding-left: 30px;
    // padding-right: 30px;
    position: relative;
}
.main-nav .navegacio-interna button:not(:focus-visible) {
    outline: 0;
}
.main-nav .navegacio-interna button abbr {
    text-decoration: none;
}
// :focus {
//   /*https://matthiasott.com/notes/focus-visible-is-here*/
//   outline: 2px dotted;
//   outline-offset: 0.25rem;
//   &:not(:focus-visible) {
//     outline: 0;
//   }
//   &:focus-visible {
//     outline: 2px dotted;
//     outline-offset: 0.25rem;
//   }
// }
.main-nav .navegacio-interna p.help-block {
    margin-top: 0;
    // padding-top: 1.5em;
    font-size: 1em;
}
.main-nav .navegacio-interna hr {
    border-color: #c8c8c8;
}
// .main-nav .navegacio-interna .btn-search {
//     position: absolute;
//     right: 24px;
//     top: 17px;
// }
.main-nav .navegacio-interna .filter_text {
    margin-bottom: 2em;
}
.main-nav .navegacio-interna .list-inline a:focus {
    font-weight: bold;
}
.main-nav .navegacio-interna ul:after {
    display: none;
}
.main-nav .navegacio-interna ul a:hover {
    background-color: transparent;
    text-decoration: underline;
}
.main-nav .navegacio-interna ul.nav-filtres {
    border-bottom: 1px solid #c8c8c8;
    // padding-bottom: 0.5rem;
    // border: 2px solid blue;
    &:last-of-type {
        border-bottom: 0;
    }
}
.main-nav .navegacio-interna ul.nav-filtres a {
    // padding-left: 0;
    // padding-right: 0;
    display: block;
    // padding: 0.5rem 2rem 0.5rem 0;
    // background-color: red;
    text-decoration: none;
    color: $body-color;
    position: relative;
    padding-right: 60px;
    &:hover {
        text-decoration: underline;
    }
    &.active {
        color: $primary;
        padding-left: 20px;
        font-weight: bold;
        &:before {
          position: absolute;
          left: 0;
          top: 6px;
          margin-right: 8px;
          display: block;
          content: ' ';
          border: solid $primary;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 4px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
        &.nolink {
            text-decoration: none;
            color: #000;
            &:before {
                border: solid #000;
                border-width: 0 3px 3px 0;
            }
        }
    }


    .badge.badge-diba {
        background-color: #f15d88;
        display: inline-block;
        min-width: 50px;
        text-align: center;
        padding: .3em 0;
        border-radius: 50px;
        color: white;
        font-size: 90%;

        position: absolute;
        right: 0;
        top: 0;

    }
}
.main-nav .navegacio-interna ul.nav-filtres span.active {
    // padding-left: 0;
    // padding-right: 0;
    display: block;
    // padding: 0.5rem 2rem 0.5rem 0;
    // background-color: red;
    text-decoration: none;
    color: $body-color;
    position: relative;
    padding-right: 60px;
    color: $primary;
    padding-left: 20px;
    font-weight: bold;
    &:before {
        position: absolute;
        left: 0;
        top: 6px;
        margin-right: 8px;
        display: block;
        content: ' ';
        border: solid $primary;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    .badge.badge-diba {
        background-color: #f15d88;
        display: inline-block;
        min-width: 50px;
        text-align: center;
        padding: .3em 0;
        border-radius: 50px;
        color: white;
        font-size: 90%;
        position: absolute;
        right: 0;
        top: 0;
    }
}


/*
.main-nav .navegacio-interna ul.nav-filtres li.border-left {
    border-left: 4px solid $primary !important;
    a.active::before {
        display:none;
    }
}
*/
.nav-filtres a.open-icon {
    margin-left: 0.5rem;
    padding: 0 0.5rem;
    position: absolute;
    right: 15px;
}

.main-nav .navegacio-interna ul.nav-filtres a.open {
    /*padding-right: 2rem;
    background: url(img/icon-open.svg) 100% 0.4rem no-repeat;
    */
    font-weight: bold;
}
.main-nav .navegacio-interna ul.nav-filtres li fieldset.filters label {
    margin-bottom: 0;
}
.main-nav .navegacio-interna .row ul.nav-filtres {
    border-bottom: none;
}
.main-nav .navegacio-interna input[type="text"] {
    font-size: 0.875em;
}
.main-nav .navegacio-interna ul.links {
    border-top: 1px solid #c8c8c8;
}
.main-nav .navegacio-interna ul.links a {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid #c8c8c8;
}
.main-nav .navegacio-interna div.primary {
    margin: 0 -30px;
}
.main-nav .navegacio-interna div.primary a:hover {
    text-decoration: none;
    background-color: #f4f4f4;
}
.no-svg .main-nav .navegacio-interna ul.nav-filtres a.open {
    background-image: url(../svg/icon-open.png);
}
// .level-2 fieldset.filters {
//     padding-right: 25px;
//     position: relative;
// }
.level-2 .navegacio-interna {
    padding-top: 30px;
    position: relative;
    z-index: 1000;
    box-shadow: 5.5px 0 5px -6px rgba(0, 0, 0, 0.24);
    background: white;
}
.level-2 .situacio.scroll-to-fixed-fixed {
    z-index: 2000 !important;
}
// .filters.open .open-icon {
//     display: block;
//     position: absolute;
//     right: 0;
//     top: 12px;
//     padding: 0;
//     line-height: 0;
// }
@media (min-width: 768px) {
    .main-nav .navegacio-interna ul.nav-filtres.col2:before,
    .main-nav .navegacio-interna ul.nav-filtres.col2:after {
        content: " ";
        display: table;
    }
    .main-nav .navegacio-interna ul.nav-filtres.col2:after {
        clear: both;
    }
    .main-nav .navegacio-interna ul.nav-filtres.col2 li {
        float: left;
        width: 50%;
        padding-right: 34px !important;
        margin-top: .5em !important;
        position: relative;

        a.open-icon {
            right: 5px;
        }
    }

    .main-nav .navegacio-interna ul.nav-filtres.col2 li:nth-child(2n) {
        padding-left: 12px;
        padding-right: 0;
    }
    .main-nav .navegacio-interna ul.nav-filtres.col2 li:nth-child(2n) {
        padding-left: 0 !important;
    }
}

// Estils nous
// -----------
form.search {
    height: 100%;
    // height: calc(100% + 1rem);
}
.main-nav.large {
    // position: absolute;
    position: relative;
    // left: 0;
    // top: 0;
    // bottom: -1rem;
    // padding-left: 0 !important;
    @include media-breakpoint-up(lg) {
        width: 140%;
        height: 100%;
        height: calc(100% + 1rem);
    }
}
.overlay-menu {
    background-color: #fff;
    // padding: 0 24px;
    // padding-top: 0px;
    // padding-right: 24px;
    // padding-left: 24px;
    opacity: 0;
    @include media-breakpoint-up(xl) {
        box-shadow: 5.5px 0 5px -6px rgba(0, 0, 0, 0.24);
    }
    &.overlay-menu-open {
        display: block;
        position: absolute;
        left:0;
        top: 0;
        -webkit-transition: opacity 1s ease-in-out;
	    -moz-transition: opacity 1s ease-in-out;
	    -ms-transition: opacity 1s ease-in-out;
	    -o-transition: opacity 1s ease-in-out;
	     opacity: 1;
    }
}

.nav-filtres {
    // @include media-breakpoint-up(xl) {
    //     column-count: 2;
    // }
    .checkbox label {
        font-weight: normal;
    }
}
fieldset.filters {
    // border: 1px solid;
    padding-right: 0;
    > div {
        display: flex;
        align-items: flex-start;
    }
    .form-group {
        margin-bottom: 0;
        flex-grow: 1;
    }
}
fieldset.hidden {
	display:none;
}
// checkboxes a la dreta
.filters {
    .custom-control {
        padding-left: 0;
        padding-right: 2rem;
    }
    .custom-control-input {
        left: auto;
        right: 0;
    }
    .custom-control-label {
        display: block;
        &::before,
        &::after {
            left: auto;
            right: -1.8rem;
        }
    }
}
