dd:first-of-type span {
  @include media-breakpoint-down(sm) {
    border-top: 0 !important;
  }
}
.list-links {
  svg {
    left: 0;
    top: 0.25em;
  }
}

.list-share {
  svg.position-absolute {
    left: 0.75em;
    top: 1em;
  }
}

.title-border {
  border-width: 0.25rem !important;
}

h2 .badge {
  position: relative;
  top: -0.5em;
}

footer .text-secondary {
  color: #000 !important;
}

.font-weight-medium {
  font-weight: 500;
}
