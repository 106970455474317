// btns home
.btn-icon {
  position: relative;
  padding: 0 3rem;
  height: 5rem;
  @include media-breakpoint-down(lg) {
    font-size: 1rem;
    height: auto;
    padding: 1rem 0.5rem;
  }
  @include media-breakpoint-up(lg) {
    padding-left: calc(1rem + 40px);
    padding-right: 1rem;
  }
  @include media-breakpoint-up(xl) {
    padding-left: calc(2rem + 66px);
    padding-right: 2rem;
  }
  svg {
    position: absolute;
    left: -0.4rem;
    top: 50%;
    transform: translateY(-50%) scale(0.6);
    @include media-breakpoint-up(xl) {
      left: 0.5rem;
      transform: translateY(-50%) scale(1);
    }
  }
}

// botó cercar
.btn-search {
  width: map-get($spacers, 5);
  height: map-get($spacers, 5);
}

// desplegables
button:not(.btn-usr)[aria-expanded="true"] svg {
  transform: rotate(0.5turn);
}

.btn-usr {
  &::after {
    display: none;
  }
  &[aria-expanded="true"] svg:last-child {
    transform: rotate(0.5turn);
  }
}

// botons login
.btn-fb,
.btn-tw,
.btn-g {
  color: #fff;
  text-align: left;
}
.btn-fb {
  background-color: #3a5a97;
  border-color: #3a5a97;
  &:hover,
  &:focus {
    background-color: darken(#3a5a97, 10%);
    border-color: darken(#3a5a97, 10%);
  }
}
.btn-tw {
  background-color: #25a4ef;
  border-color: #25a4ef;
  &:hover,
  &:focus {
    background-color: darken(#25a4ef, 10%);
    border-color: darken(#25a4ef, 10%);
  }
}
.btn-g {
  background-color: #db463d;
  border-color: #db463d;
  &:hover,
  &:focus {
    background-color: darken(#db463d, 10%);
    border-color: darken(#db463d, 10%);
  }
}
.btn-social {
  border-width: 1px;
}

// botó eliminar criteri de cerca
.wrapper .close {
  background: url("img/ico-tancar.svg") no-repeat 0 50%;
  padding-left: 2.5rem !important;
}
