.flashMessages {
  @media screen and (min-width: 768px) {
    position: fixed;
    top: 100px;
    right: 40px;
    max-width: 20%;
    z-index: 10000;
  }
  .alert {
    &.alert-error {
      background-color: #f2dede;
      border-color: #ebccd1;
      color: #a94442;
    }
  }

  .close {
    opacity: 1;
    font-size: 1rem;
    background: transparent;
    border: none;
    
  }

  .alert + .alert {
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 768px) {
    .alert {
      padding: 50px;
      width: 100%;

    }
  }
}