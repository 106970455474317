.bopcalendar--wrapper {
    background-color: #fff;
    border-radius: 9px;
    .datepicker {
        width: 100%;
        padding: 2rem;
    }
    table {
        width: 100%;
        td {
            padding-top: 0.75em;
            padding-bottom: 0.75em;
        }
        th {
            padding-top: 0.5em;
            padding-bottom: 0.5em;
        }
    }
    .prev,
    .next {
        &::before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 26px;
            height: 26px;
        }
        &:hover {
            background-color: transparent;
        }
    }
    .prev {
        &::before {
            background-image: url("img/bt-calendario-anterior.svg");
        }
    }
    .next {
        &::before {
            background-image: url("img/bt-calendario-siguiente.svg");
        }
    }
    .datepicker table tr td:not(.disabled) {
        color: #000;
        font-weight: bold;
    }
    .datepicker table tr td.old,
    .datepicker table tr td.new {
        font-weight: 300;
    }
    .datepicker table tr td.disabled,
    .datepicker table tr td.disabled:hover .datepicker table tr td.old {
        color: #767676;
    }
    .datepicker-switch {
        font-size: 1.125rem;
    }
    .datepicker-days thead {
        tr:last-child {
            th {
                height: 0.75rem;
            }
        }
    }
}
