.days-list {
  li {
    @include media-breakpoint-up(lg) {
      padding-left: 17px;
      padding-right: 17px;
    }
  }
  span {
    &:first-child {
      font-size: 0.75rem;
      font-weight: 500;
      color: #7a7a7a;
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
    }
    &:nth-child(2) {
      font-size: 2.125rem;
      color: #c0c0c0;
      background-color: #fcfcfc;
      transition: 0.15s ease-in;
      transition-property: background-color, color;
      @include media-breakpoint-up(xl) {
        // font-size: 4.375rem;
        font-size: 2.75rem;
      }
    }
    &:last-child {
      font-size: 0.75rem;
      // @include media-breakpoint-up(lg) {
      //   font-size: 0.875rem;
      // }
    }
  }
  a:hover {
    text-decoration: none !important;
    span {
      &:nth-child(2) {
        color: #000;
        background-color: #fff;
      }
    }
  }
  li.active {
    flex-grow: 0;
    flex-basis: 10rem;
    // @include media-breakpoint-up(lg) {
    //   flex-basis: 8%;
    // }
    span {
      &:nth-child(2) {
        font-size: 4.375rem;
        border-width: 4px !important;
      }
      &:last-child {
        font-size: 0.875rem;
      }
    }
    a:hover {
      span {
        &:nth-child(2) {
          background-color: transparent !important;
        }
      }
    }
  }
}
