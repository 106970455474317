label:not(.custom-control-label) {
  font-weight: $font-weight-bold;
  // padding-left: 0.5em;
}
textarea.form-control {
  border-radius: 0.5rem;
}
.custom-range {
  border: 0;
}
