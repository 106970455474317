.logo-diba {
  width: 80px;
  height: 26px;
  @include media-breakpoint-up(sm) {
    height: 44px;
    width: auto;
  }
}
.site-title {
  font-size: 0.875rem;
  @include media-breakpoint-up(sm) {
    font-size: 1.25rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.75rem;
  }
}
